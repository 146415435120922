<template>
  <div class="wrapper">
    <div class="first-page">
      <div class="head">
        <h1 class="head__title">
          <span class="ko_font"
            >제 {{ anketa.period_number }} 회 한국어능력시험 {{ anketa.is_ibt ? "IBT" : "PBT" }} 응시원서</span
          >
          <span class="ru_font"
            >Анкета регистрации {{ anketa.period_number }}-ого {{ anketa.is_ibt ? "IBT" : "PBT" }} тестирования на
            знание корейского языка</span
          >
        </h1>
      </div>
      <div class="table">
        <div class="table-head__reg-num">
          <span class="ko_font">*접수번호</span>
          <span class="ru_font"
            >Регистрационный номер No.: {{ anketa?.registration_number }}</span
          >
        </div>
        <div class="table-head">
          <div class="table-head__item head-item__level">
            <span class="ko_font"><span>1</span> 시험수준</span>
            <span class="ru_font">Уровень</span>
          </div>
          <div class="table-head__item head-item__reg-num">
            <span class="ko_font"
              >* <span>2</span>
              <span class="ru_font" style="border: none">ɡ</span>수험번호</span
            >
            <span class="ru_font">Registration No.</span>
          </div>
          <div class="table-head__item head-item__country">
            <span class="ko_font"><span>3</span> 응시지역</span>
            <span class="ru_font">Место сдачи экзамена</span>
          </div>
          <div class="table-head__item head-item__topic-level">
            <span class="ko_font">한국어능력시험</span>
            <span class="ru_font"
              >(TOPIK
              <span style="border: none" v-if="anketa.topik_type == 1">I</span
              ><span style="border: none" v-else>II</span>)</span
            >
          </div>
          <div class="table-head__item head-item__topic-date-2">
            <span class="ru_font"
              >TOPIK
              <span style="border: none" v-if="anketa.topik_type == 1">I</span
              ><span style="border: none" v-else>II</span> -
              {{ getFullDate(anketa.exam_time) }}</span
            >
          </div>

          <div class="table-head__item head-item__reg-id ru_font">
            <span>0</span>
            <span>0</span>
            <span>7</span>
            <span
              v-for="num in getRegionOrdering(anketa.region_code_city)"
              :key="num.id"
              >{{ num }}</span
            >
            <template v-if="anketa.is_ibt">
              <span v-if="anketa.topik_type === 1">4</span>
              <span v-if="anketa.topik_type === 2">6</span>
            </template>
            <template v-else>
              <span v-if="anketa.topik_type === 1">7</span>
              <span v-if="anketa.topik_type === 2">8</span>
            </template>
            <span
              v-for="num in getCountryOrdering(anketa.region_code_place)"
              :key="num.id"
              >{{ num }}</span
            >
            <span
              v-for="item in getRegNum(anketa.registration_number)"
              :key="item.id"
              >{{ item }}</span
            >
          </div>
          <div class="table-head__item head-item__country-name">
            <span class="ko_font">{{ anketa.region_name_ko }}</span>
            <span class="ru_font">{{ anketa.region_name }}</span>
          </div>
          <div class="table-head__item head-item__user-photo">
            <img :src="anketa.face" alt="anketa" />
          </div>
        </div>
        <div class="table-main">
          <div class="table-main__item main-item__name">
            <span class="ko_font">성명</span><span class="ru_font">ФИО</span>
          </div>
          <div class="table-main__item main-item__name-ko">
            <span class="ko_font"><span>4</span> 한글</span
            ><span class="ru_font">на кор. яз</span>
          </div>
          <div class="table-main__item main-item__name-en">
            <span class="ko_font"><span>5</span> 영문</span
            ><span class="ru_font">на анг.яз</span>
          </div>
          <div class="table-main__item main-item__birth-day">
            <span class="ko_font"><span>9</span> 생년월일</span
            ><span class="ru_font">Дата рожд</span>
          </div>
          <div class="table-main__item main-item__address">
            <span class="ko_font"><span>10</span> 주소</span
            ><span class="ru_font">Адрес</span>
          </div>
          <div class="table-main__item main-item__notification">
            <span class="ko_font"><span>11</span> 응시동기</span
            ><span class="ru_font">Источник информации</span>
          </div>
          <div class="table-main__item main-item__goal">
            <span class="ko_font"><span>12</span> 응시목적 </span
            ><span class="ru_font">цель участия</span>
          </div>
          <div class="table-main__item main-item__full-name">
            <span class="ko_font">성+ 이름</span
            ><span class="ru_font">Фамилия, имя</span>
          </div>
          <div class="table-main__item main-item__gender">
            <span class="ko_font"><span>6</span> 성별</span
            ><span class="ru_font">Пол</span>
          </div>
          <div class="table-main__item main-item__citizenship">
            <span class="ko_font"><span>7</span> 국적</span
            ><span class="ru_font">Гражданство</span>
          </div>
          <div class="table-main__item main-item__profession">
            <span class="ko_font"><span>8</span> 직업</span
            ><span class="ru_font">Профессия</span>
          </div>
          <div class="table-main__item main-item__gender-select">
            <div>
              <span class="icon">
                <img src="@/assets/pdf/checkbox-empty.png" alt="" />
                <img
                  src="@/assets/pdf/check-icon.png"
                  v-if="anketa.sex == 'M' || anketa.sex == 'm'"
                  alt=""
                />
              </span>
              <span class="ko_font">남</span> <span class="ru_font">Муж</span>
            </div>
            <div>
              <span class="icon">
                <img src="@/assets/pdf/checkbox-empty.png" alt="" />
                <img
                  src="@/assets/pdf/check-icon.png"
                  v-if="anketa.sex == 'F' || anketa.sex == 'f'"
                  alt=""
                />
              </span>
              <span class="ko_font">여</span> <span class="ru_font">Жен</span>
            </div>
          </div>
          <div class="table-main__item main-item__nationality">
            <div>
              <span class="ko_font">민족</span
              ><span class="ru_font">Национальность</span>
            </div>
            <div>
              <span class="ko_font">
                {{
                  getFilterChoices(
                    allChoices.nationality,
                    anketa.nationality,
                    "ko"
                  )
                }} </span
              ><span class="ru_font">{{
                getFilterChoices(
                  allChoices.nationality,
                  anketa.nationality,
                  "ru"
                )
              }}</span>
            </div>
          </div>
          <div class="table-main__item main-item__tel-num">
            <span class="ko_font">전화번호&nbsp;</span
            ><span class="ru_font">Номер телефона</span>
          </div>
          <div class="table-main__item main-item__email">
            <span class="ko_font">이메일&nbsp;</span
            ><span class="ru_font">E-mail</span>
          </div>
          <div class="table-main__item main-item__index">
            <span class="ko_font">우편번호&nbsp;</span
            ><span class="ru_font">Почтовый индекс</span>
          </div>
          <div class="table-main__item main-item__home-tel">
            <div>
              <span class="ko_font">자택&nbsp;</span
              ><span class="ru_font">Домашний:</span>
            </div>
            <div>
              <span class="ru_font">{{ anketa.home_phone }}</span>
            </div>
          </div>
          <div class="table-main__item main-item__cellular">
            <div>
              <span class="ko_font">핸드폰&nbsp;</span
              ><span class="ru_font">Сотовый:</span>
            </div>
            <div>
              <span class="ko_font">{{ anketa.phone }}</span>
            </div>
          </div>
          <div class="table-main__item main-item__full-name-ko">
            <span class="ko_font">{{
              anketa.last_name_ko + " " + anketa.first_name_ko
            }}</span>
          </div>
          <div class="table-main__item main-item__full-name-en">
            <span class="ru_font">{{
              anketa.last_name + " " + anketa.first_name
            }}</span>
          </div>
          <div class="table-main__item main-item__full-birth">
            <span class="ko_font">
              {{ getFullBirth(anketa.birth_date, "ko") }}
            </span>

            <span class="ru_font">
              {{ getFullBirth(anketa.birth_date, "ru") }}
            </span>
          </div>
          <div class="table-main__item main-item__citizenship-country">
            <span class="ko_font">
              {{
                getFilterChoices(
                  allChoices.citizenship,
                  anketa.citizenship,
                  "ko"
                )
              }} </span
            ><span class="ru_font">{{
              getFilterChoices(allChoices.citizenship, anketa.citizenship, "ru")
            }}</span>
          </div>
          <div class="table-main__item main-item__profession-type">
            <div
              v-for="(employ, index) in allChoices.employment"
              :key="employ.id"
            >
              <span class="icon">
                <img src="@/assets/pdf/checkbox-empty.png" alt="" />
                <img
                  src="@/assets/pdf/check-icon.png"
                  v-if="anketa.employment == employ.id"
                  alt=""
                />
              </span>
              <span class="ko_font">{{ index + 1 }}.{{ employ.name_ko }}</span>
              <span class="ru_font">{{ employ.name_ru }}</span>
            </div>
          </div>
          <div class="table-main__item main-item__address-value">
            <span class="ru_font">{{ anketa.address }}</span>
          </div>
          <div class="table-main__item main-item__email-value">
            <span class="ru_font">{{ anketa.email }}</span>
          </div>
          <div class="table-main__item main-item__index-value">
            <span class="ru_font">{{ anketa.postal_index }}</span>
          </div>
          <div class="table-main__item main-item__notification-items">
            <div
              v-for="(source, index) in allChoices.inform_source"
              :key="source.id"
            >
              <span class="icon">
                <img src="@/assets/pdf/checkbox-empty.png" alt="" />
                <img
                  src="@/assets/pdf/check-icon.png"
                  v-if="anketa.inform_source == source.id"
                  alt=""
                />
              </span>
              <span class="ko_font"
                ><span>{{ index + 1 }}.</span>{{ source.name_ko }}</span
              ><span class="ru_font">{{ source.name_ru }}</span>
            </div>
          </div>
          <div class="table-main__item main-item__goal-items">
            <div v-for="(goal, index) in allChoices.purpose" :key="goal.id">
              <span class="icon">
                <img src="@/assets/pdf/checkbox-empty.png" alt="" />
                <img
                  src="@/assets/pdf/check-icon.png"
                  v-if="anketa.purpose == goal.id"
                  alt=""
                />
              </span>
              <span class="ko_font"
                ><span>{{ index + 1 }}.</span>{{ goal.name_ko }}</span
              >
              <span class="ru_font">{{ goal.name_ru }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="first-page__bottom">
        <h2 class="first-page__bottom-allow">
          <span class="ko_font"
            >*시험 응시에 관하여 개인정보 수집 및 이용, 제3자 제공에
            동의합니다.</span
          >
          <span class="ru_font"
            >(To permit NIIED to use my personal information for the
            TOPIK.)</span
          >
        </h2>
        <div class="first-page__bottom-name">
          <div class="ru_font">
            Applicant’s name: {{ anketa.last_name + " " + anketa.first_name }},
            signature_______________
          </div>
          <div>
            <span class="ko_font">여권번호</span>
            <span class="ru_font">(серия паспорта): {{ anketa.id_doc }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="second-page">
      <div class="second-page__top">
        <div class="second-page__top-title">
          <span class="ko_font"
            >제 {{ anketa.period_number }} 회 한국어능력시험 응시수험표</span
          >
          <span class="ru_font"
            >Бланк участника {{ anketa.period_number }} -го тестирования на
            знание корейского языка</span
          >
        </div>
        <div class="table">
          <div class="table-head">
            <div class="table-head__item head-item__topic-date">
              <span class="ru_font"
                >TOPIK
                <span style="border: none" v-if="anketa.topik_type == 1">I</span
                ><span style="border: none" v-else>II</span> -
                {{ getFullDate(anketa.exam_time) }}</span
              >
            </div>
            <div class="table-head__item head-item__exam-num">
              <span class="ko_font"><span>14</span>수험번호</span>
              <span class="ru_font">Экзаменационный номер</span>
            </div>
            <div class="table-head__item head-item__num ru_font">
              <span>0</span>
              <span>0</span>
              <span>7</span>
              <span
                v-for="num in getRegionOrdering(anketa.region_code_city)"
                :key="num.id"
                >{{ num }}</span
              >
              <template v-if="anketa.is_ibt">
                <span v-if="anketa.topik_type === 1">4</span>
                <span v-if="anketa.topik_type === 2">6</span>
              </template>
              <template v-else>
                <span v-if="anketa.topik_type === 1">7</span>
                <span v-if="anketa.topik_type === 2">8</span>
              </template>
              <span
                v-for="num in getCountryOrdering(anketa.region_code_place)"
                :key="num.id"
                >{{ num }}</span
              >
              <span
                v-for="item in getRegNum(anketa.registration_number)"
                :key="item.id"
                >{{ item }}</span
              >
            </div>
          </div>
          <div class="table-main">
            <div class="table-main__item main-item__user-photo">
              <img :src="anketa.face" alt="anketa" />
            </div>
            <div class="table-main__item main-item__level">
              <span class="ko_font"><span>15</span>시험수준</span
              ><span class="ru_font">Уровень</span>
            </div>
            <div class="table-main__item main-item__country">
              <span class="ko_font"><span>16</span>시험지역(도시)</span>
              <span class="ru_font"
                >Место <br />
                проведения экзамена (город)</span
              >
            </div>
            <div class="table-main__item main-item__organization">
              <span class="ko_font"><span>17</span>시험장소(기관)</span>
              <span class="ru_font"
                >Место проведения экзамена (организация)</span
              >
            </div>
            <div class="table-main__item main-item__full-name">
              <span class="ko_font"><span>18</span>성명</span>
              <span class="ru_font">ФИО</span>
            </div>
            <div class="table-main__item main-item__gender">
              <span class="ko_font"><span>19</span>성별(남/여)</span>
              <span class="ru_font">Пол<br />M / Ж</span>
            </div>
            <div class="table-main__item main-item__birth-day">
              <span class="ko_font"><span>20</span>생년월일(년/월/일)</span>
              <span class="ru_font">дата рождения(год/месяц/число)</span>
              <span class="ko_font">년 월 일</span>
            </div>
            <div class="table-main__item main-item__topic-level">
              <span class="ru_font"
                >TOPIK
                <span style="border: none" v-if="anketa.topik_type == 1">I</span
                ><span style="border: none" v-else>II</span></span
              >
            </div>
            <div class="table-main__item main-item__country-name">
              <span class="ru_font">{{ anketa.region_name }}</span>
            </div>
            <div class="table-main__item main-item__address">
              <span :class="anketa.exam_address?.match(/[\uac00-\ud7af]|[\u1100-\u11ff]|[\u3130-\u318f]|[\ua960-\ua97f]|[\ud7b0-\ud7ff]/g) ? 'ko_font' : 'ru_font'">{{ anketa.exam_address }}</span>
            </div>
            <div class="table-main__item main-item__name">
              <span class="ru_font">{{
                anketa.last_name + " " + anketa.first_name
              }}</span>
            </div>
            <div class="table-main__item main-item__gender-type">
              <span class="ru_font" v-if="anketa.sex == 'M'">М</span>
              <span class="ru_font" v-else>Ж</span>
            </div>
            <div class="table-main__item main-item__birth-date">
              <span class="ru_font">{{ anketa.birth_date }}</span>
            </div>
          </div>
        </div>
        <div class="second-page__text">
          <span class="ko_font">접수자 확인: __________________ (인)</span>
          <span class="ru_font" style="border: none"
            >1.<span class="ko_font" style="font-size: 18px; border: none"
              >접수번호 및 수험번호란('※로 표시된 곳)을 제외한 모든 란을 기입
              하십시오
            </span>
            <span class="ru_font"
              >заполните все пункты, за исключением пунктов со знаком '※'.>
              пункт со знаком «※» заполняется сотрудниками ЦОРКа 2.</span
            >
            <span>1</span>, <span>6</span>, <span>8</span>, <span>11</span>,
            <span>12</span>
            <span
              class="ko_font"
              style="border: none; color: #000; display: inline"
              >란은 해당란 √표를 하십 시오 </span
            >&#60;
            <span class="ru_font">
              пункты <span>1</span>, <span>6</span>, <span>8</span>,
              <span>11</span>, <span>12</span> отметьте знаком «√»</span
            >
            3.<span class="ko_font" style="border: none"
              >응시수험표는 시험일날 반드시 지참 하십시오</span
            >
            <span class="ru_font"
              >данный бланк участника необходимо предоставить на экзамене></span
            >
            4.<span class="ko_font" style="border: none"
              >응시수험표 는 시험결과 통지 시까지 보관 하십시오</span
            >
            <span class="ru_font"
              >сохраните бланк участника до оповещания результатов
              экзамена.></span
            >
          </span>
        </div>
        <img src="@/assets/pdf/dashed-bg.png" alt="anketa" />
      </div>
      <div class="second-page__bottom"></div>
    </div>
    <div class="third-page">
      <div class="third-page__inner">
        <div class="third-page__column">
          <h3 class="third-page__title ko_font">
            응시 수수료 영수증(기관보관용)
          </h3>
          <div class="table-first">
            <div class="table-first__item item-first__name">
              <span class="ko_font">응시 수수료 영수증</span>
            </div>
            <div class="table-first__item item-first__num">
              <span class="ru_font">No. ______</span>
            </div>
            <div class="table-first__item item-first__test-name">
              <span class="ko_font">시험명</span>
            </div>
            <div class="table-first__item item-first__count-1">
              <span class="ko_font">성<br />명</span>
            </div>
            <div class="table-first__item item-first__count-2">
              <span class="ko_font">성<br />명</span>
            </div>
            <div class="table-first__item item-first__count-3">
              <span class="ko_font">(2)한<br />글</span>
            </div>
            <div class="table-first__item item-first__count-4">
              <span class="ko_font">(3)영<br />문</span>
            </div>
            <div class="table-first__item item-first__birth-day">
              <span class="ko_font">(4)생년월일</span>
            </div>
            <div class="table-first__item item-first__pay">
              <span class="ko_font">응시수수료</span>
            </div>
            <div class="table-first__item item-first__chairman">
              <span class="ko_font">타슈켄트토픽위원회위원장</span>
            </div>
            <div class="table-first__item item-first__you">
              <span class="ko_font">귀하</span>
            </div>
            <div class="table-first__item item-first__test-id">
              <span class="ko_font"
                >(1)제 {{ anketa.period_number }} 회 한국어능력시험</span
              >
            </div>
            <div class="table-first__item item-first__name-ko">
              <span class="ko_font">{{
                anketa.last_name_ko + " " + anketa.first_name_ko
              }}</span>
            </div>
            <div class="table-first__item item-first__name-en">
              <span class="ru_font">{{
                anketa.last_name + " " + anketa.first_name
              }}</span>
            </div>
            <div class="table-first__item item-first__birth-date">
              <span class="ru_font">{{ anketa.birth_date }}</span>
            </div>
            <div class="table-first__item item-first__empty"></div>
            <div class="table-first__item item-first__pay-text">
              <span class="ko_font"
                >위 금액을 제 {{ anketa.period_number }} 회 한국어능력시험<br />응시
                수수료로 정히 영수합니다.<br />{{ getCurrentYear() }}년 ___월
                ___일
              </span>
            </div>
          </div>
        </div>
        <div class="third-page__column">
          <h3 class="third-page__title ko_font">
            응시 수수료 영수증(개인보관용)
          </h3>
          <div class="table-first table-second">
            <div class="table-first__item item-first__name">
              <span class="ko_font">응시 수수료 영수증</span>
            </div>
            <div class="table-first__item item-first__num">
              <span class="ru_font">No. ______</span>
            </div>
            <div class="table-first__item item-first__test-name">
              <span class="ko_font">시험명</span>
            </div>
            <div class="table-first__item item-first__count-1">
              <span class="ko_font">성<br />명</span>
            </div>
            <div class="table-first__item item-first__count-2">
              <span class="ko_font">성<br />명</span>
            </div>
            <div class="table-first__item item-first__count-3">
              <span class="ko_font">한<br />글</span>
            </div>
            <div class="table-first__item item-first__count-4">
              <span class="ko_font">영<br />문</span>
            </div>
            <div class="table-first__item item-first__birth-day">
              <span class="ko_font">생년월일</span>
            </div>
            <div class="table-first__item item-first__pay">
              <span class="ko_font">응시수수료</span>
            </div>
            <div class="table-first__item item-first__chairman">
              <span class="ko_font">타슈켄트토픽위원회위원장</span>
            </div>
            <div class="table-first__item item-first__you">
              <span class="ko_font">취급자 영수인</span>
            </div>
            <div class="table-first__item item-first__test-id">
              <span class="ko_font"
                >(1)제 {{ anketa.period_number }} 회 한국어능력시험</span
              >
            </div>
            <div class="table-first__item item-first__name-ko">
              <span class="ko_font">{{
                anketa.last_name_ko + " " + anketa.first_name_ko
              }}</span>
            </div>
            <div class="table-first__item item-first__name-en">
              <span class="ru_font">{{
                anketa.last_name + " " + anketa.first_name
              }}</span>
            </div>
            <div class="table-first__item item-first__birth-date">
              <span class="ru_font">{{ anketa.birth_date }}</span>
            </div>
            <div class="table-first__item item-first__empty"></div>
            <div class="table-first__item item-first__pay-text">
              <span class="ko_font"
                >위 금액을 제 {{ anketa.period_number }} 회 한국어능력시험<br />응시
                수수료로 정히 영수합니다.<br />{{ getCurrentYear() }}년 ___월
                ___일
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scan-page">
      <div class="scan-page__title ru_font">
        Скан паспорта пользователя
        {{ anketa.last_name + " " + anketa.first_name }} по заявке с номером
        <span>0</span>
        <span>0</span>
        <span>7</span>
        <span
          v-for="num in getRegionOrdering(anketa.region_code_city)"
          :key="num.id"
          >{{ num }}</span
        >
        <template v-if="anketa.is_ibt">
          <span v-if="anketa.topik_type === 1">4</span>
          <span v-if="anketa.topik_type === 2">6</span>
        </template>
        <template v-else>
          <span v-if="anketa.topik_type === 1">7</span>
          <span v-if="anketa.topik_type === 2">8</span>
        </template>
        <span
          v-for="num in getCountryOrdering(anketa.region_code_place)"
          :key="num.id"
          >{{ num }}</span
        >
        <span
          v-for="item in getRegNum(anketa.registration_number)"
          :key="item.id"
          >{{ item }}</span
        >
      </div>
      <div class="scan-page__img">
        <img :src="anketa.document" alt="anketa" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useFormatter } from "@/utils/formatter";

export default {
  name: "Home",
  components: {},
  props: {
    anketa: Object,
  },
  setup() {
    const formatter = useFormatter();
    const allChoices = computed(() => store.getters["appeal/allChoices"]);

    const getFullBirth = (birth, lang) => {
      let date = new Date(birth);
      let currentDate = new Date();

      const options = { month: "long", day: "numeric", year: "numeric" };

      if (lang === "ko") {
        return `${date.toLocaleDateString("ko", options)} (만${
          currentDate.getFullYear() - date.getFullYear()
        }세)`;
      } else if (lang === "ru") {
        return `${formatter.handleDate(birth, "dd.mm.yyyy")} (${
          currentDate.getFullYear() - date.getFullYear()
        } лет)`;
      }
    };

    const getFullDate = (fullDate) => {
      let date = new Date(fullDate);

      const options = {
        hour: "numeric",
        minute: "numeric",
        month: "long",
        day: "numeric",
      };

      return date.toLocaleDateString("ru", options);
    };

    const getCurrentYear = () => {
      let date = new Date();
      return date.getFullYear();
    };

    const route = useRoute();

    const store = useStore();

    const getFilterChoices = (choices, id, lang) => {
      if (choices != undefined && id != undefined) {
        let filteredChoice = choices.filter((choice) => choice.id == id);
        if (lang === "ru") {
          return filteredChoice[0].name_ru;
        } else if (lang === "ko") {
          return filteredChoice[0].name_ko;
        }
      }
    };

    const inner = ref(null);

    const getCountryOrdering = (code) => {
      if (code || code == 0) {
        let strOrder = code.toString();
        console.log(code);
        if (code >= 10) {
          return strOrder;
        } else {
          return "0" + strOrder;
        }
      } else if (code === 0) {
        return "00";
      } else {
        return "__";
      }
    };

    const getRegionOrdering = (code) => {
      if (code || code == 0) {
        let strOrder = code.toString();
        if (code >= 10) {
          return "0" + strOrder;
        } else if (code >= 100) {
          return strOrder;
        } else {
          return "00" + strOrder;
        }
      } else if (code === 0) {
        return "000";
      } else {
        return "___";
      }
    };

    const getRegNum = (num) => {
      // let strNum = num.toString()

      if (num === undefined || num === null) {
        return "____";
      } else {
        for (let i = 0; i <= 3; i++) {
          if (num.length < 4) {
            num += "_";
          }
        }
        return num;
      }
    };

    getRegNum(1);

    return {
      allChoices,
      getRegNum,
      getCurrentYear,
      getFilterChoices,
      store,
      getFullBirth,
      route,
      getCountryOrdering,
      getRegionOrdering,
      ...useFormatter(),
      getFullDate,
      inner,
    };
  },
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

img {
  background: none;
}

.ko_font {
  font-family: NanumGothicCoding;
  font-weight: bold;
  font-size: 0.9em;
}
.ru_font {
  font-family: Roboto;
  font-size: 0.9em;
}

.wrapper {
  max-width: 1050px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  background: #fff;
  left: -1284px;
  color: #000;
  // height: 100vh;
  // overflow: scroll;
  overflow: hidden;
}

.first-page {
  width: 1024px;
  height: 1309px;
  padding-top: 27px;
  background-position-x: -3px;
  margin-bottom: 210px;

  .head {
    &__title {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  .table {
    &-head {
      display: grid;
      grid-template-columns: 278px 195px 347px 196px;
      grid-template-rows: calc(91px / 2) calc(91px / 2) 68px 68px;
      margin-bottom: 32px;

      &__reg-num {
        font-size: 22px;
      }

      &__item {
        width: 100%;
        height: 100%;
        border: 1px solid #000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 19px;
        span {
          span {
            border: 1px solid #000;
            padding: 0 1px;
          }
        }
      }

      .head-item {
        &__level {
          grid-column: 1 / 2;
          grid-row: 1 / 3;
        }

        &__reg-num {
          grid-row: 3 / 4;
        }

        &__country {
          grid-row: 4 / 5;
        }

        &__country-name {
          grid-column: 2 / 4;
          grid-row: 4 / 5;
        }

        &__topic-level {
          grid-row: 1 / 3;
        }

        &__topic-date {
          &-2 {
            grid-column: 3 / 4;

            grid-row: 1 / 3;
          }
        }

        &__reg-id {
          display: flex;
          flex-direction: row;
          grid-column: 2 / 4;
          grid-row: 3 / 4;
          font-size: 25px;

          span {
            width: 43px;
            height: 43px;
            display: flex;
            justify-content: center;
            border-right: 1px solid #000;

            &:last-child {
              border: none;
            }
          }
        }

        &__user-photo {
          grid-row: 1 / 5;

          img {
            width: 154px;
            height: 216px;
            object-fit: contain;
          }
        }
      }
    }

    &-main {
      display: grid;
      grid-template-columns: 121px 254px 134px 239px 270px;
      grid-template-rows: 59px 1fr 1fr 2fr 36px 59px 35px 36px 119px 119px;

      &__item {
        width: 100%;
        height: 100%;
        border: 1px solid #000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 19px;
        text-align: center;

        span {
          span {
            border: 1px solid #000;
            padding: 0 1px;
          }
        }
      }

      .main-item {
        &__name {
          &-ko {
            grid-row: 2 / 3;
            grid-column: 1 / 2;
          }

          &-en {
            grid-row: 3 / 4;
            grid-column: 1 / 2;
          }
        }

        &__birth-day {
          grid-column: 1 / 2;
          grid-row: 4 / 5;
        }

        &__address {
          grid-column: 1 / 2;
          grid-row: 5 / 9;
        }

        &__notification {
          grid-column: 1 / 2;
          grid-row: 9 / 10;
        }

        &__goal {
          grid-column: 1 / 2;
          grid-row: 10 / 11;
          padding: 0 5px;
        }

        &__gender-select {
          grid-column: 3 / 4;
          grid-row: 2 / 5;
          padding-top: 4px;
          justify-content: flex-start;

          .icon {
            position: relative;
            img {
              width: 16px;
              height: 16px;
              transform: translateY(3px);
              margin-right: 3px;
              &:nth-child(2) {
                position: absolute;
                top: -1px;
                left: 2px;
              }
            }
          }
        }

        &__nationality {
          grid-column: 4 / 5;
          grid-row: 4 / 5;
          justify-content: flex-start;
          padding-top: 3px;

          span {
            display: block;
          }
        }

        &__tel-num {
          grid-column: 2 / 3;
          grid-row: 6 / 7;
        }

        &__email {
          grid-column: 2 / 3;
          grid-row: 7 / 8;
          flex-direction: row;

          span {
            &:first-child {
              margin-right: 3px;
            }
          }
        }

        &__index {
          grid-column: 2 / 3;
          grid-row: 8 / 9;
          flex-direction: row;
          span {
            &:first-child {
              margin-right: 3px;
            }
          }
        }

        &__home-tel {
          grid-column: 3 / 5;
          grid-row: 6 / 7;
          padding-top: 2px;
        }

        &__cellular {
          grid-column: 5 / 6;
          grid-row: 6 / 7;
          padding-top: 2px;
        }

        &__full-name-ko {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
        }

        &__full-name-en {
          grid-column: 2 / 3;
          grid-row: 3 / 4;
        }

        &__full-birth {
          grid-column: 2 / 3;
          grid-row: 4 / 5;
        }

        &__citizenship-country {
          grid-column: 4 / 5;
          grid-row: 2 / 4;
          justify-content: flex-start;
          padding-top: 4px;
        }

        &__profession-type {
          overflow: hidden;
          grid-column: 5 / 6;
          grid-row: 2 / 5;
          justify-content: flex-start;
          align-items: flex-start;
          padding-left: 5px;
          padding-top: 5px;

          word-wrap: break-word;
          div {
            display: flex;
            flex-wrap: wrap;
          }

          .icon {
            position: relative;
            img {
              width: 16px;
              height: 16px;
              transform: translateY(3px);
              margin-right: 3px;
              &:nth-child(2) {
                position: absolute;
                top: -1px;
                left: 2px;
              }
            }
          }
        }

        &__address-value {
          grid-column: 2 / 6;
          grid-row: 5 / 5;
        }

        &__email-value {
          grid-column: 3 / 6;
          grid-row: 7 / 8;
        }

        &__index-value {
          grid-column: 3 / 6;
          grid-row: 8 / 9;
        }

        &__notification-items {
          overflow: hidden;
          grid-column: 2 / 6;
          grid-row: 9 / 10;
          text-align: start;
          align-items: flex-start;
          justify-content: space-around;
          flex-wrap: wrap;
          padding: 5px 10px;
          font-size: 17px;
          div {
            width: 25%;
          }

          .icon {
            position: relative;
            img {
              width: 16px;
              height: 16px;
              transform: translateY(3px);
              margin-right: 3px;
              &:nth-child(2) {
                position: absolute;
                top: -1px;
                left: 2px;
              }
            }
          }

          span {
            span {
              border: none;
            }
          }
        }

        &__goal-items {
          overflow: hidden;
          grid-column: 2 / 6;
          grid-row: 10 / 11;
          text-align: start;
          align-items: flex-start;
          justify-content: space-around;
          flex-wrap: wrap;
          padding: 0px 10px;
          font-size: 17px;

          div {
            width: 25%;
          }

          span {
            span {
              border: none;
            }
          }

          .icon {
            position: relative;
            img {
              width: 16px;
              height: 16px;
              transform: translateY(3px);
              margin-right: 3px;
              &:nth-child(2) {
                position: absolute;
                top: -1px;
                left: 2px;
              }
            }
          }
        }
      }
    }
  }

  &__bottom {
    &-allow {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;
      font-size: 24px;
      margin-top: 30px;
      margin-bottom: 32px;
    }

    &-name {
      display: flex;
      font-size: 22px;
      flex-direction: column;
    }
  }
}

.second-page {
  width: 1024px;
  height: 687px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-x: -4px;
  margin-bottom: 310px;

  &__text {
    margin-top: 29px;
    span {
      display: block;

      &:first-child {
        font-size: 23px;
        padding-left: 2px;
      }

      &:last-child {
        font-size: 18px;
        line-height: 21px;
      }

      span {
        display: inline;
        border: 1px solid rgb(0, 0, 0);
        font-size: 18px;
      }

      .ru_font {
        border: none;
        font-size: 18px;

        span {
          font-size: 18px;
        }
      }
    }
  }

  &__top {
    &-title {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-size: 24px;
      margin-bottom: 2px;
    }

    .table {
      &-head {
        width: 100%;
        height: 98px;
        display: grid;
        grid-template-columns: 484px 229px 302px;
        margin-bottom: 24px;

        &__item {
          width: 100%;
          height: 100%;
          border: 1px solid #000;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 18px;

          span {
            span {
              border: 1px solid #000;
              padding: 0 1px;
            }
          }
        }

        .head-item {
          &__exam-num {
            span {
              &:last-child {
                width: max-content;
              }
            }
          }
          &__num {
            flex-direction: row;
            justify-content: space-around;

            span {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 24px;
              border-right: 1px solid #000;
            }
          }
        }
      }

      &-main {
        width: 100%;
        display: grid;
        grid-template-columns: 172px 298px 165px 381px;
        grid-template-rows: 100px 77px 77px 34px;

        &__item {
          width: 100%;
          height: 100%;
          border: 1px solid #000;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          font-size: 18px;

          span {
            span {
              border: 1px solid #000;
              padding: 0 1px;
            }
          }
        }

        .main-item {
          &__user-photo {
            grid-row: 1 / 5;
            justify-content: center;
            grid-column: 1 / 2;

            img {
              width: 153px;
              height: 205px;
              object-fit: contain;
            }
          }

          &__level {
            grid-row: 1 / 2;
            grid-column: 2 / 3;
            padding-top: 3px;
          }

          &__country {
            padding-top: 3px;
          }

          &__organization {
            padding-top: 3px;
          }

          &__full-name {
            grid-row: 3 / 4;
            grid-column: 2 / 3;
            padding-top: 3px;
          }

          &__gender {
            grid-row: 3 / 4;
            grid-column: 3 / 4;
            padding-top: 3px;
          }

          &__birth-day {
            grid-row: 3 / 4;
            grid-column: 4 / 5;
            padding-top: 3px;
          }

          &__topic-level {
            justify-content: center;
          }

          &__country-name {
            justify-content: center;
          }

          &__address {
            justify-content: center;
            padding: 3px;
          }

          &__name {
            justify-content: center;
          }

          &__gender-type {
            justify-content: center;
          }

          &__birth-date {
            justify-content: center;
          }
        }
      }
    }
  }
}

.third-page {
  width: 1024px;
  height: 500px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-x: -4px;
  padding-right: 7px;
  margin-bottom: 25px;

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__column {
    width: 499px;
    text-align: center;
  }

  &__title {
    font-size: 20px;
    margin-bottom: 37px;
  }

  .table-first {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 83px 83px 107px 227px;
    grid-template-rows: 34px 34px 34px 57px 55px 35px 34px 78px 34px;

    &__item {
      width: 100%;
      height: 100%;
      border: 1px solid #000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }

    .item-first {
      &__name {
        grid-column: 1 / 5;
        align-items: flex-start;
        padding-left: 5px;
      }

      &__num {
        grid-column: 1 / 5;
        grid-row: 2 / 3;
        align-items: flex-start;
        padding-left: 5px;
      }
      &__test-name {
        grid-column: 1 / 3;
        grid-row: 3 / 4;
      }

      &__count {
        &-1 {
          grid-column: 1 / 2;
          grid-row: 4 / 5;
        }
        &-2 {
          grid-column: 1 / 2;
          grid-row: 5 / 6;
        }
        &-3 {
          grid-column: 2 / 3;
          grid-row: 4 / 5;
        }
        &-4 {
          grid-column: 2 / 3;
          grid-row: 5 / 6;
        }
      }

      &__birth-day {
        grid-column: 1 / 3;
        grid-row: 6 / 7;
      }

      &__pay {
        grid-column: 1 / 3;
        grid-row: 7 / 8;
      }

      &__chairman {
        grid-column: 1 / 4;
        grid-row: 9 / 10;
        padding-left: 4px;
        align-items: flex-start;
      }

      &__you {
        grid-column: 4 / 5;
        grid-row: 9 / 10;
        align-items: flex-start;
        padding-left: 4px;
      }

      &__test-id {
        grid-column: 3 / 5;
        grid-row: 3 / 4;
        padding-left: 4px;
        align-items: flex-start;
      }

      &__name {
        &-ko {
          grid-column: 3 / 5;
          grid-row: 4 / 5;
          padding-left: 4px;
          align-items: flex-start;
        }

        &-en {
          grid-column: 3 / 5;
          grid-row: 5 / 6;
          padding-left: 4px;
          align-items: flex-start;
        }
      }

      &__birth-date {
        grid-column: 3 / 5;
        grid-row: 6 / 7;
        padding-left: 4px;
        align-items: flex-start;
      }

      &__empty {
        grid-column: 3 / 5;
        grid-row: 7 / 8;
      }

      &__pay-text {
        grid-column: 1 / 5;
        grid-row: 8 / 9;
        padding-bottom: 5px;
      }
    }
  }

  .table-second {
    grid-template-columns: 97px 104px 130px 167px;
  }
}

.scan-page {
  width: 1024px;

  &__title {
    font-size: 18px;
    color: #878787;
    border-bottom: 1px dashed #878787;
    padding-bottom: 3px;
    margin-bottom: 30px;
    span {
      margin-right: 2px;
    }
  }

  &__img {
    width: 923px;
    height: 1302px;
    margin: 0 auto;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
</style>
